import { captchaDecorator } from '@shared/helpers/captcha';

import Api, { ApiOptions } from './ApiProvider';
import {
  RequestSignResult,
  SocialInterface,
  TelegramUser,
  WalletInterface,
  WalletType,
  WalletTypeInUppercase,
} from './types';

export const logout = (options?: ApiOptions<{ json?: { name: string } }>) => {
  return Api.post('v2/magicid/auth/logout', options).json<{ message: string }>();
};

interface AuthorizationStatus {
  id: string;
  type: string;
  attributes: {
    authorized: boolean;
  };
}

export const getIsUserAuthorized = (options?: ApiOptions) => {
  return Api.get('magicid/user/authorized', options).json<AuthorizationStatus>();
};

export const loginSendPasswordlessEmail = (
  options?: ApiOptions<{ json: { email: string; referredBy?: string } }>,
) => {
  return Api.post('v2/magicid/auth/login/email', options).json();
};

export const loginSendGoogleOAuthCode = (
  options?: ApiOptions<{ json: { code: string; referredBy?: string } }>,
) => {
  return Api.post('v2/magicid/auth/login/google', options).json();
};

export const loginVerifyWallet = (
  options?: ApiOptions<{
    json: { network: string; pub_key: string; wallet: string; signature: string; message: string };
  }>,
) => Api.post('v2/magicid/auth/login/wallet', options).json();

export const loginConnectWallet = (
  options?: ApiOptions<{
    json: { network: string; pub_key: string; wallet: string; referredBy?: string };
  }>,
) => Api.post('v2/magicid/user/wallet/connect', options).json();

export const loginTestUser = (options: ApiOptions<{ json: { id: number } }>) =>
  Api.post('v2/magicid/auth/login/test-user', options).json<{ message: string }>();

export const loginWallet = async (
  options?: ApiOptions<{
    json: {
      network: string;
      pub_key: string;
      wallet: string;
      signature: string;
      message: string;
      referredBy?: string;
    };
  }>,
) => {
  await loginVerifyWallet(options);

  return loginConnectWallet(options);
};

export const loginWithWallet = captchaDecorator<
  ApiOptions<{
    json: Pick<RequestSignResult, 'signature' | 'network' | 'message'> & {
      pub_key: RequestSignResult['address'];
      wallet: WalletTypeInUppercase;
    };
  }>,
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    message: 'Authorized' | (string & {});
    referral: boolean;
    newUser: boolean;
    error?: any;
  }
>(async (options) => Api.post('v2/magicid/auth/login/wallet', options).json(), 'loginWithWallet');

export const connectToMagicID = (
  options: ApiOptions<{
    json: Pick<RequestSignResult, 'network'> & {
      pub_key: RequestSignResult['address'];
      wallet: WalletType;
      referredBy?: string;
    };
  }>,
) =>
  Api.post('v2/magicid/user/wallet/connect', options).json<{
    connected: number;
    createdAt: string;
    displayedName: string | null;
    email: string | null;
    emailCodeInvalidAttempts: number;
    emailConfirmed: number;
    id: number;
    name: string | null;
    normalized_email: string | null;
    referral_id: string | null;
    referred_by_id: string | null;
    registration_device: string | null;
    registration_ip: string | null;
    socials: (SocialInterface['attributes'] & Pick<SocialInterface, 'id'>)[];
    updatedAt: string;
    uuid: string;
    wallets: (WalletInterface['attributes'] & Pick<WalletInterface, 'id'>)[];
    error?: unknown;
  }>();

export const sendGoogleOAuthCode = captchaDecorator<
  {
    json: { code: string; referredBy?: string };
  },
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    message: 'Authorized' | (string & {});
    newUser: boolean;
  }
>(
  async (options) => Api.post('v2/magicid/auth/login/google', options).json(),
  'sendGoogleOAuthCode',
  true,
);

export const sentPasswordlessEmail = captchaDecorator<
  {
    json: { email: string; referredBy?: string };
  },
  {
    attributes: { email: string };
    email: string;
    id: number;
    type: 'JOIN_LINK';
  }
>(
  async (options) => Api.post('v2/magicid/auth/login/email', options).json(),
  'sentPasswordlessEmail',
);

export const deleteWallet = ({ id }: { id: number }) =>
  Api.delete(`v2/magicid/user/wallets/${id}`).json();

export const confirmExternalConnect = (options: ApiOptions<{ json: { hash: string } }>) =>
  Api.post('v2/magicid/external/magic-boost/connect', options).json();

// Method only for testing `magic-boost.connect` socket events
export const triggerMagicBoostExternalConnect = (
  options: ApiOptions<{ json: { magicId: number; name: string; access: string[] } }>,
) => Api.post('v1/magicid/external/connect', options).json();

export const loginByTelegram = (
  options: ApiOptions<{
    json: { userData: TelegramUser; userCreationAllowed: boolean; referredBy?: string };
  }>,
) => Api.post('v2/magicid/auth/login/telegram', options).json();

export const getXLoginUrl = (options?: ApiOptions) =>
  Api.get('v2/magicid/auth/login/x', options).json<{ meta: { link: string } }>();

export const loginByX = (
  options?: ApiOptions<{
    json: {
      oauthToken: string;
      oauthVerifier: string;
      userCreationAllowed?: boolean;
      referredBy?: string;
    };
  }>,
) => Api.post('v2/magicid/auth/login/x', options).json<{ meta: { link: string } }>();

export const getDiscordLoginUrl = (options?: ApiOptions) => {
  return Api.get('v2/magicid/auth/login/discord', options).json<{ url: string }>();
};

export const loginByDiscord = (
  options?: ApiOptions<{
    json: { code: string; referredBy?: string; userCreationAllowed?: boolean };
  }>,
) => Api.post('v2/magicid/auth/login/discord', options).json<{ message: string }>();
